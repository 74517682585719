import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as FRDIcon } from 'assets/imgs/marketCap/FRD.svg'
import FRDImg from 'assets/imgs/marketCap/FRD.svg'
import { ReactComponent as APRIcon } from 'assets/imgs/stake/apr.svg'
import ClaimBtn from 'assets/imgs/stake/claimBtn.svg'
import ClaimBtnHover from 'assets/imgs/stake/claimBtn-hover.svg'
import { ReactComponent as ETHIcon } from 'assets/imgs/stake/eth.svg'
import { ReactComponent as LockedIcon } from 'assets/imgs/stake/locked.svg'
import { ReactComponent as TVLIcon } from 'assets/imgs/stake/tvl.svg'
import { BaseButton, Pending } from 'components/Button'
import { PageWrapper } from 'components/PageWrapper'
import { useActiveChainId } from 'connection/useActiveChainId'
import { BaseToken_ADDRESSES } from 'constants/addresses'
import { useBaseStakingContract } from 'hooks/useContract'
import { useCallback, useState } from 'react'
import { useHttpEthPrice, useHttpRoxPrice } from 'state/http/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { useApr, useBaseTokenSupply, useRewards, useStakedToken, useTotalSupply } from './hooks'
import StakeDialog from './StakeDialog'
import StakeInfo from './StakeInfo'
import UnstakeDialog from './UnStakeDialog'
import { isBsc } from 'constants/tokens'

const StyledAPRIcon = styled(APRIcon)`
  .bg {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

const StyledLockedIcon = styled(LockedIcon)`
  .bg {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

const StyledTVLIcon = styled(TVLIcon)`
  .bg {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

const StyledInfoList = styled(Box)`
  margin-top: 64px;
  display: flex;
  gap: 110px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    gap: 64px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    gap: 32px;
    flex-direction: column;
  }
`

const StyledInfo = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.themeBg};
  border-radius: 8px;
  .title {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  }
  .info {
    padding: 16px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 100%;
  }
`

const StyledClaimBtn = styled(BaseButton)`
  width: 216px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-image: url(${ClaimBtn});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    background-image: url(${ClaimBtnHover});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &:disabled {
    cursor: not-allowed;
  }
`

export const StyledStakeBtn = styled(BaseButton)`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%);
  font-size: 14px;
  font-weight: bold;
  &:hover {
    border: 1px solid ${({ theme }) => theme.claimBtn_border};
    background: ${({ theme }) => theme.claimBtn};
    color: ${({ theme }) => theme.claimBtn_text};
    box-shadow: 0px 0px 16px 0px ${({ theme }) => theme.primaryBtn_hover};
  }
  &:disabled {
    background: ${({ theme }) => theme.primaryBtn_disabled};
    color: ${({ theme }) => theme.textTertiary};
    cursor: not-allowed;
    pointer-events: auto;
    box-shadow: none;
    outline: none;
  }
`

export const StyledUnstakeBtn = styled(BaseButton)`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.networkBg};
  color: ${({ theme }) => theme.networkBg};
  font-size: 14px;
  font-weight: bold;
  &:hover {
    background: ${({ theme }) => theme.networkBg};
    color: ${({ theme }) => theme.backgroundModule2};
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    box-shadow: none;
    outline: none;
  }
`

export default function Stake() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const baseTokenSupply = useBaseTokenSupply()
  const totalSupply = useTotalSupply()
  const ethPrice = useHttpEthPrice()
  const roxPrice = useHttpRoxPrice()

  const rewards = useRewards()
  const apr = useApr()
  const stakedAmount = useStakedToken()
  const [openStake, setOpenStake] = useState(false)
  const handleOpenStakeDialog = () => {
    setOpenStake(true)
  }
  const handleCloseStake = () => {
    setOpenStake(false)
  }

  const StakingContract = useBaseStakingContract()
  const { chainId, account, provider } = useActiveChainId()
  const addTransaction = useTransactionAdder()
  const handlerClaim = useCallback(async () => {
    if (!StakingContract || !account) return
    setAttemptingTxn(true)
    try {
      const response = await StakingContract.getReward(account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('claim error', error)
    }
  }, [StakingContract, account, addTransaction])

  const [openUnstake, setOpenUnstake] = useState(false)
  const handleOpenUnstakeDialog = () => {
    setOpenUnstake(true)
  }
  const handleCloseUnstake = () => {
    setOpenUnstake(false)
  }

  return (
    <PageWrapper>
      <ThemedText.TextPrimary fontWeight={800} fontSize={48} color="primaryTitle">
        <Trans>Stake</Trans>
      </ThemedText.TextPrimary>
      <ThemedText.TextSecondary fontWeight={300} fontSize={16} mt="12px">
        <Trans>
          After this round has ended, Blast Gold will be distributed based on Market Capitilzation Rankings.
        </Trans>
      </ThemedText.TextSecondary>
      <StyledInfoList>
        <Box display="flex" gap="8px" alignItems="center">
          <StyledAPRIcon />
          <Box display="flex" flexDirection="column" gap="8px" justifyContent="flex-start">
            <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
              <Trans>Stake APR</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={20}>
              {apr?.gt(0) ? formatAmount(apr, 2, true) : 0}%
            </ThemedText.TextPrimary>
          </Box>
        </Box>
        <Box display="flex" gap="8px" alignItems="center">
          <StyledTVLIcon />
          <Box display="flex" flexDirection="column" gap="8px" justifyContent="flex-start">
            <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
              <Trans>TVL</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={20}>
              {formatAmount(totalSupply, 2, true)} LFG
            </ThemedText.TextPrimary>
          </Box>
        </Box>
        <Box display="flex" gap="8px" alignItems="center">
          <StyledLockedIcon />
          <Box display="flex" flexDirection="column" gap="8px" justifyContent="flex-start">
            <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
              <Trans>% Circ.Locked</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={20}>
              {totalSupply && baseTokenSupply ? formatAmount(totalSupply?.div(baseTokenSupply).times(100), 2, true) : 0}
              %
            </ThemedText.TextPrimary>
          </Box>
        </Box>
      </StyledInfoList>
      <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap="16px" alignItems="flex-start" mt="60px">
        <StyledInfo>
          <div className="title">
            <ThemedText.TextPrimary fontWeight={800} fontSize={16}>
              <Trans>Your Staked</Trans>
            </ThemedText.TextPrimary>
          </div>
          <div className="info">
            <Box
              display="flex"
              flexDirection={isVerySmallScreen ? 'column' : 'row'}
              gap={isVerySmallScreen ? '16px' : '8px'}
              alignItems="flex-start"
            >
              <Box display="flex" gap="16px" alignItems="center" className="flex-1">
                <FRDIcon width="48px" height="48px" />
                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                  <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                    <Trans>Staked</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontWeight={700} fontSize={20}>
                    {formatAmount(stakedAmount, 2, true)} LFG
                  </ThemedText.TextPrimary>
                  <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                    ${stakedAmount && roxPrice ? formatAmount(stakedAmount.times(BN(roxPrice)), 2, true) : 0}
                  </ThemedText.TextSecondary>
                </Box>
              </Box>
              {/* <Box display="flex" flexDirection="column" justifyContent="flex-start" width="140px">
                <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                  <Trans>Est.APR</Trans>
                </ThemedText.TextSecondary>
                <Box display="flex" gap="6px" alignItems="center">
                  <ThemedText.TextPrimary fontWeight={700} fontSize={20}>
                    {apr?.gt(0) ? formatAmount(apr, 2, true) : 0}%
                  </ThemedText.TextPrimary>
                  <UpIcon />
                </Box>
                <ThemedText.TextTertiary fontWeight={400} fontSize={12}>
                  --%
                </ThemedText.TextTertiary>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="flex-start" width="140px">
                <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                  <Trans>Boost MULT.</Trans>
                </ThemedText.TextSecondary>
                <ThemedText.TextPrimary fontWeight={700} fontSize={20}>
                  --%
                </ThemedText.TextPrimary>
              </Box> */}
            </Box>
            <Box display="flex" gap="16px" mt="48px">
              <div className="flex-1">
                <StyledStakeBtn onClick={handleOpenStakeDialog}>
                  <ThemedText.TextPrimary color="white" fontSize={14} fontWeight={700}>
                    <Trans>STAKE</Trans>
                  </ThemedText.TextPrimary>
                </StyledStakeBtn>
              </div>
              <div className="flex-1">
                <StyledUnstakeBtn onClick={handleOpenUnstakeDialog}>
                 UNSTAKE
                </StyledUnstakeBtn>
              </div>
            </Box>
          </div>
        </StyledInfo>
        <StyledInfo>
          <div className="title">
            <ThemedText.TextPrimary fontWeight={800} fontSize={16}>
              <Trans>Rewards</Trans>
            </ThemedText.TextPrimary>
          </div>
          <Box
            display="flex"
            flexDirection={isVerySmallScreen ? 'column' : 'row'}
            gap={isVerySmallScreen ? '16px' : '8px'}
            justifyContent="space-between"
            alignItems="center"
            className="info"
          >
            <Box display="flex" gap="16px" alignItems="center">
              <ETHIcon width="48px" height="48px" />
              <Box display="flex" flexDirection="column" justifyContent="flex-start">
                <ThemedText.TextPrimary fontWeight={700} fontSize={20}>
                  {rewards?.gt(0) ? formatAmount(rewards, 4, true) : 0} {isBsc(chainId) ? 'BNB' : "ETH"}
                </ThemedText.TextPrimary>
                <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                  ${rewards?.gt(0) && ethPrice ? formatAmount(rewards.times(BN(ethPrice)), 2, true) : 0}
                </ThemedText.TextSecondary>
              </Box>
            </Box>
            <StyledClaimBtn onClick={handlerClaim} disabled={attemptingTxn}>
              <ThemedText.TextPrimary color="white" fontSize={16} fontWeight={700} mt="-8px">
                <Trans>{attemptingTxn ? <Pending /> : 'CLAIM'}</Trans>
              </ThemedText.TextPrimary>
            </StyledClaimBtn>
          </Box>
        </StyledInfo>
      </Box>
      <Box mt="40px">
        <StakeInfo />
      </Box>
      <StakeDialog
        open={openStake}
        handleClose={handleCloseStake}
        stakedAmount={stakedAmount}
        apr={apr}
        tokenName="FRD"
        tokenIcon={FRDImg}
        tokenAddress={BaseToken_ADDRESSES[chainId]}
        StakingContract={StakingContract}
      />
      <UnstakeDialog
        open={openUnstake}
        handleClose={handleCloseUnstake}
        stakedAmount={stakedAmount}
        StakingContract={StakingContract}
        tokenName="FRD"
        tokenIcon={FRDImg}
      />
    </PageWrapper>
  )
}
