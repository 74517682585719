import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { Pool } from '@uniswap/v3-sdk'
import { AllPoolsProps } from 'hooks/useQueryAllPools'
import { ParsedQs } from 'qs'
import { ReactNode, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { isAddress } from '../../utils'
import {
  Field,
  positionSelectCurrency,
  positionSwitchCurrencies,
  positionTypeInput,
  setLimit,
  setLong,
  setPoolInfo,
  setSliederLever,
  setToken0,
  TRADESATE,
} from './actions'
import { SwapState } from './reducer'

export function usePositionSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onCurrencySelectionstring: (field: Field, currency: string) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeLong: (isLong: TRADESATE) => void
  onSliederLever: (sliederLever: number) => void
  onTradeInfo: (poolInfo: AllPoolsProps) => void
  onSetisToken0: (istoken0: boolean) => void
  onsetIsLimit: (islimit: boolean) => void
} {
  const dispatch = useAppDispatch()

  const state = useTradeSWAPState()

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        positionSelectCurrency({
          field,
          currencyId: currency.isToken ? currency.address : currency.isNative ? 'BNB' : '',
        })
      )
    },
    [dispatch]
  )
  const onCurrencySelectionstring = useCallback(
    (field: Field, currency: string) => {
      dispatch(
        positionSelectCurrency({
          field,
          currencyId: currency,
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(positionSwitchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(positionTypeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeLong = useCallback(
    (isLong: TRADESATE) => {
      if (!state.poolInfo) return
      if (state.isToken0) {
        if (isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        }
      } else {
        if (isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        }
      }

      dispatch(setLong({ isLong }))
      onUserInput(Field.INPUT, '')
    },
    [dispatch, onCurrencySelection, onUserInput, state.isToken0, state.poolInfo]
  )

  const onSliederLever = useCallback(
    (sliederLever: number) => {
      dispatch(setSliederLever({ sliederLever }))
    },
    [dispatch]
  )
  const onTradeInfo = useCallback(
    (poolInfo: AllPoolsProps) => {
      if (state.isToken0) {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, poolInfo.token1)
        } else {
          onCurrencySelection(Field.INPUT, poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, poolInfo.token0)
        }
      } else {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, poolInfo.token0)
        } else {
          onCurrencySelection(Field.INPUT, poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, poolInfo.token1)
        }
      }
      dispatch(setPoolInfo({ poolInfo }))
    },
    [dispatch, onCurrencySelection, state.isLong, state.isToken0]
  )

  const onSetisToken0 = useCallback(
    (isToken0: boolean) => {
      if (!state.poolInfo) return
      if (isToken0) {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        }
      } else {
        if (state.isLong == TRADESATE.LONG) {
          onCurrencySelection(Field.INPUT, state.poolInfo.token1)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token0)
        } else {
          onCurrencySelection(Field.INPUT, state.poolInfo.token0)
          onCurrencySelection(Field.OUTPUT, state.poolInfo.token1)
        }
      }
      dispatch(setToken0({ isToken0 }))
      onUserInput(Field.INPUT, '')
    },
    [dispatch, onCurrencySelection, onUserInput, state.isLong, state.poolInfo]
  )

  const onsetIsLimit = useCallback(
    (islimit: boolean) => {
      dispatch(setLimit({ limit: islimit }))
      onUserInput(Field.INPUT, '')
    },
    [dispatch, onUserInput]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeLong,
    onSliederLever,
    onTradeInfo,
    onCurrencySelectionstring,
    onSetisToken0,
    onsetIsLimit,
  }
}

export function useTradeSWAPState() {
  return useAppSelector((state) => state.tradeSwap)
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f': true, // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a': true, // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D': true, // v2 router 02
}

type SwapInfo = {
  currencies: { [field in Field]?: Currency | null }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmount?: CurrencyAmount<Currency>
  inputError?: ReactNode
  tradePool: {
    isLoading: boolean
    pool?: Pool
    poolAddr?: string
  }
  useInpuntAmount: {
    inputAmount?: CurrencyAmount<Currency>
    outputAount?: CurrencyAmount<Currency>
  }
  allowedSlippage: Percent
  autoSlippage: Percent
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    const upper = urlParam.toUpperCase()
    if (upper === 'ETH') return 'ETH'
    if (upper in TOKEN_SHORTHANDS) return upper
  }
  return ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount)
  const independentField = parseIndependentFieldURLParameter(parsedQs.exactField)

  if (inputCurrency === '' && outputCurrency === '' && typedValue === '' && independentField === Field.INPUT) {
    // Defaults to having the native currency selected
    inputCurrency = 'ETH'
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = ''
  }

  // const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === '' ? null : inputCurrency ?? null,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === '' ? null : outputCurrency ?? null,
    },
    typedValue,
    independentField,
    isLong: TRADESATE.LONG,
    isToken0: true,
    sliederLever: 1.1,
    poolInfo: undefined,
    isLimit: false,
  }
}
