import { Trans } from '@lingui/macro'
import { Box, Collapse } from '@mui/material'
import CreateBtnIcon from 'assets/imgs/marketCap/createBtn.svg'
import createBtnHover from 'assets/imgs/marketCap/createBtnHover.svg'
import { ReactComponent as UpLoadIcon } from 'assets/imgs/marketCap/upLoad.svg'
import axios from 'axios'
import { BaseButton } from 'components/Button'
import Column from 'components/Column'
import { PageWrapper } from 'components/PageWrapper'
import Row from 'components/Row'
import { ToastError } from 'components/Toast'
import { useActiveChainId } from 'connection/useActiveChainId'
import { keccak256, solidityPack } from 'ethers/lib/utils'
import { useLaunchpadContract, useTokenFactoryContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { handlerError } from 'utils/formatError'
import isZero from 'utils/isZero'

import { numberToHex } from '../InfoDetail/Swap'
import { StyledBorderBox } from '../StyledBorderBox'
import ConfirmDialog from './ConfirmDialog'
import { CHAIN_API_URL } from 'graphql/thegraph/apollo'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'

const StyledCreateBox = styled(StyledBorderBox)`
  width: 500px;
  max-width: 100%;
  padding: 16px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 100%;
  }
`

const Input = styled.input<{ error?: boolean }>`
  font-size: 16px;
  outline: none;
  flex: 1 1 auto;
  width: 0;
  height: 48px;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.accentFailure : theme.textPrimary)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.textTertiary};
    font-weight: 400;
  }
  padding: 16px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const StyledUpLoad = styled(Box)`
  width: 124px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.backgroundInteractive};
  border: 2px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.primaryBtn_hover};
  }
`

const StyledPreview = styled(Box)`
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background: ${({ theme }) => theme.backgroundInteractive};
`

export const StyledCreateBtn = styled(BaseButton)`
  width: 468px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-image: url(${CreateBtnIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(${createBtnHover});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &:disabled {
    cursor: not-allowed;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 300px;
    height: 37px;
  }
`

const getTokenKey = (account: string, name: string, symbol: string) => {
  const tokenKey = keccak256(solidityPack([`address`, `string`, `string`], [account, name, symbol]))
  return tokenKey
}

export default function Create() {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const navigate = useNavigate()
  const [buyAmount, setBuyAmount] = useState('0')

  const [imgUrl, setImgUrl] = useState('')
  const [nameInput, setNameInput] = useState('')
  const [tickerInput, setTickerInput] = useState('')
  const [descInput, setDescInput] = useState('')
  const [twitterInput, setTwitterInput] = useState('')
  const [telegramInput, setTelegramInput] = useState('')
  const [discordInput, setDiscordInput] = useState('')
  const [websiteInput, setWebsiteInput] = useState('')
  const handleNameInput = (e: any) => {
    setNameInput(e.target.value)
  }
  const handleTickerInput = (e: any) => {
    setTickerInput(e.target.value)
  }
  const handleDescInput = (e: any) => {
    setDescInput(e.target.value)
  }
  const handleTwitterInput = (e: any) => {
    setTwitterInput(e.target.value)
  }
  const handleTelegramInput = (e: any) => {
    setTelegramInput(e.target.value)
  }
  const handleDiscordInput = (e: any) => {
    setDiscordInput(e.target.value)
  }
  const handleWebsiteInput = (e: any) => {
    setWebsiteInput(e.target.value)
  }
  const [open, setOpen] = useState(false)
  const handleOpenDialog = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const onUserInput = (amount: any) => {
    setBuyAmount(amount)
  }

  const [show, setShow] = useState(false)

  const handleShowMore = () => {
    setShow(!show)
  }

  const { account,chainId } = useActiveChainId()
  const nav = useNativeCurrency(chainId)
  const inputRef = useRef<HTMLInputElement>(null)
  const handlerUpload = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleFileChange = async (event: any) => {
    const fileImg = event.target.files[0]
    if (!fileImg || !account) return
    const formData = new FormData()
    formData.append('file', fileImg)
    // formData.append('name', nameInput)
    try {
      const response = await axios.post(`${CHAIN_API_URL[chainId]}/upload/${account?.toLowerCase()}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log(response.data.data, 'response')
      setImgUrl(response.data.data)
    } catch (error) {
      console.log({ error })
      ToastError('Upload Error :' + error.response.data)
    }
  }

  const TokenFactoryContract = useTokenFactoryContract()

  const tokenKey = useMemo(() => {
    if (!account || !nameInput || !tickerInput) return undefined
    return getTokenKey(account, nameInput, tickerInput)
  }, [account, nameInput, tickerInput])

  const handlerCreateToken = useCallback(async () => {
    const parmasData = {
      image_url: imgUrl,
      token_key: tokenKey,
      description: descInput,
      twitter: twitterInput,
      telegram: telegramInput,
      website: websiteInput,
      discord: discordInput,
    }
    try {
      const response = await axios.post(`${CHAIN_API_URL[chainId]}/create_token`, parmasData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log(response.data, 'response')
      if (response.data.code == 200) {
        return true
      }
    } catch (error) {
      return false
    }
    return false
  }, [chainId,account, descInput, discordInput, imgUrl, nameInput, telegramInput, tickerInput, twitterInput, websiteInput])

  const LaunchpadContract = useLaunchpadContract()

  const { result: createFee } = useSingleCallResult(LaunchpadContract ?? undefined, 'createFee', [])
  const { result: maxBuy } = useSingleCallResult(LaunchpadContract ?? undefined, 'autoBuyMax', [])

  const { result: tokenAddress } = useSingleCallResult(TokenFactoryContract ?? undefined, 'tokenForKey', [
    tokenKey ?? undefined,
  ])
  useEffect(() => {
    if (tokenAddress && !isZero(tokenAddress[0])) {
      setTimeout(() => navigate(`/infoDetail?token=${tokenAddress[0]}`), 3000)
    }
  }, [tokenAddress])
  const addTransaction = useTransactionAdder()
  const handlerCreate = useCallback(async () => {
    if (!LaunchpadContract || !createFee) return
    if (!nameInput) {
      ToastError('Please enter Name')
      return
    }
    if (!tickerInput) {
      ToastError('Please enter Ticker')
      return
    }
    if (!descInput) {
      ToastError('Please enter Description')
      return
    }
    if (!imgUrl) {
      ToastError('Please upload the Image')
      return
    }
    if (!tokenKey) {
      return
    }

    setAttemptingTxn(true)
    let response
    try {
      const TX = await handlerCreateToken()
      if (!TX) {
        setAttemptingTxn(false)
        ToastError('Please re-fill the information')
        return
      }
      response = await LaunchpadContract.createTokenPool(nameInput, tickerInput, numberToHex(buyAmount), {
        value: BN(createFee[0].toString()).plus(numberToHex(buyAmount)).plus(1).toFixed(),
      })
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CREATE,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('create error:', error)
    }
  }, [
    LaunchpadContract,
    createFee,
    nameInput,
    tickerInput,
    descInput,
    imgUrl,
    tokenKey,
    handlerCreateToken,
    buyAmount,
    addTransaction,
  ])

  return (
    <PageWrapper className="create">
      <Box display="flex" justifyContent="center">
        <StyledCreateBox>
          <Column gap="16px">
            <Column align="flex-start" gap="8px" width="100%">
              <Box display="flex" alignItems="center">
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>Name</Trans>
                </ThemedText.TextSecondary>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700} color="removeBackground" ml="2px">
                  *
                </ThemedText.TextSecondary>
              </Box>
              <Input
                type="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                placeholder="Name"
                onChange={handleNameInput}
                value={nameInput}
              />
            </Column>
            <Column align="flex-start" gap="8px" width="100%">
              <Box display="flex" alignItems="center">
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>Ticker</Trans>
                </ThemedText.TextSecondary>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700} color="removeBackground" ml="2px">
                  *
                </ThemedText.TextSecondary>
              </Box>
              <Input
                type="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                placeholder="Ticker"
                onChange={handleTickerInput}
                value={tickerInput}
              />
            </Column>
            <Column align="flex-start" gap="8px" width="100%">
              <Box display="flex" alignItems="center">
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>Description</Trans>
                </ThemedText.TextSecondary>
                <ThemedText.TextSecondary fontSize={12} fontWeight={700} color="removeBackground" ml="2px">
                  *
                </ThemedText.TextSecondary>
              </Box>
              <Input
                type="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                placeholder="Description"
                onChange={handleDescInput}
                value={descInput}
              />
            </Column>
            <Row align="stretch" gap="16px">
              <Column align="flex-start" gap="8px">
                <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                  <Trans>Image</Trans>
                </ThemedText.TextSecondary>
                <input type="file" onChange={handleFileChange} ref={inputRef} style={{ display: 'none' }} />
                <StyledUpLoad onClick={handlerUpload}>
                  <UpLoadIcon />
                  <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                    <Trans>Upload</Trans>
                  </ThemedText.TextPrimary>
                </StyledUpLoad>
              </Column>
              {imgUrl ? <img src={imgUrl} width="70px" height="70px" /> : <StyledPreview />}
            </Row>
            <Row justify="flex-start" mt="16px">
              <ThemedText.TextPrimary
                fontSize={14}
                fontWeight={700}
                className="active-gradient-text"
                style={{ cursor: 'pointer' }}
                onClick={handleShowMore}
              >
                <Trans>
                  Show More Options
                  {/* {show ? <ExpandLess /> : <ExpandMore />} */}
                </Trans>
              </ThemedText.TextPrimary>
            </Row>
            <Collapse in={show} timeout="auto" unmountOnExit style={{ width: '100%' }}>
              <Column gap="16px" width="100%">
                <Column align="flex-start" gap="8px" width="100%">
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    <Trans>Twitter</Trans>
                  </ThemedText.TextSecondary>
                  <Input
                    type="text"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder="https://x.com/"
                    onChange={handleTwitterInput}
                    value={twitterInput}
                  />
                </Column>
                <Column align="flex-start" gap="8px" width="100%">
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    <Trans>Telegram</Trans>
                  </ThemedText.TextSecondary>
                  <Input
                    type="text"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder="https://t.me/"
                    onChange={handleTelegramInput}
                    value={telegramInput}
                  />
                </Column>
                <Column align="flex-start" gap="8px" width="100%">
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    <Trans>Discord</Trans>
                  </ThemedText.TextSecondary>
                  <Input
                    type="text"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder="https://discord.com/invite/"
                    onChange={handleDiscordInput}
                    value={discordInput}
                  />
                </Column>
                <Column align="flex-start" gap="8px" width="100%">
                  <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                    <Trans>Website</Trans>
                  </ThemedText.TextSecondary>
                  <Input
                    type="text"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder="https://"
                    onChange={handleWebsiteInput}
                    value={websiteInput}
                  />
                </Column>
              </Column>
            </Collapse>
            <Box mt="10px">
              <StyledCreateBtn onClick={handleOpenDialog}>
                <ThemedText.TextPrimary color="white" fontSize={16} fontWeight={700} mt="-8px">
                  <Trans>Create</Trans>
                </ThemedText.TextPrimary>
              </StyledCreateBtn>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                <Trans>Cost to deploy</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
                {createFee ? fromWei(createFee[0].toString()).toString() : '0'} {nav.symbol}
              </ThemedText.TextPrimary>
            </Box>
          </Column>
        </StyledCreateBox>
      </Box>
      <ConfirmDialog
        open={open}
        handleClose={handleClose}
        attemptingTxn={attemptingTxn}
        handlerCreate={handlerCreate}
        buyAmount={buyAmount}
        onUserInput={onUserInput}
        createFee={createFee}
        name={nameInput}
        maxBuy={maxBuy}
      />
    </PageWrapper>
  )
}
