import { Trans } from '@lingui/macro'
import { Box, Dialog } from '@mui/material'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { ReactComponent as CloseIcon } from 'assets/imgs/stake/close.svg'
import { ReactComponent as ToIcon } from 'assets/imgs/stake/to.svg'
import { Pending } from 'components/Button'
import { useDynamicApprove } from 'components/DynamicApprove'
import MaxTab from 'components/MaxTab'
import NumericalInput from 'components/NumericalInput'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { numberToHex, StyledInputBox } from 'pages/MarketCap/InfoDetail/Swap'
import { useCallback, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { StyledStakeBtn } from '.'

export const StyledDialog = styled(Dialog)`
  background-color: rgba(13, 17, 28, 0.32);
  backdrop-filter: blur(8px);
  .MuiPaper-root {
    margin: 0;
    padding: 16px;
    width: 400px;
    max-width: 100%;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.backgroundModule};
    border-radius: 16px;
    border: 1px solid #1ea7e4;
    box-shadow: 5px 4px 0px 0px #00000040;
  }
  .MuiDialog-root {
    
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    .MuiPaper-root {
      padding: 8px;
      width: 96vw;
    }
  }
`

export const StyledInfo = styled(Box)`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 16px;
  .info-item {
    padding: 8px;
    &:not(:last-child) {
      border-bottom: 1px dashed ${({ theme }) => theme.primaryBorder};
    }
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 8px;
  }
`

export default function StakeDialog({
  open,
  handleClose,
  stakedAmount,
  apr,
  tokenName,
  tokenIcon,
  tokenAddress,
  StakingContract,
}: any) {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const { chainId, account, provider } = useActiveChainId()

  const [stakeAmount, setStakeAmount] = useState('')
  const onUserInput = (amount: any) => {
    setStakeAmount(amount)
  }

  const tokenCurrency = useCurrency(tokenAddress)
  const amountCurrency: CurrencyAmount<Currency> | undefined = useMemo(() => {
    if (!tokenCurrency || !stakeAmount) return undefined
    return CurrencyAmount.fromRawAmount(tokenCurrency, numberToHex(stakeAmount))
  }, [tokenCurrency, stakeAmount])

  const baseBalance = useCurrencyBalance(account ?? undefined, tokenCurrency ?? undefined)

  const { DynamicApprove, isApproved } = useDynamicApprove([amountCurrency], StakingContract?.address)

  const isInsufficientBalance = useMemo(() => {
    if (!stakeAmount || stakeAmount == '0' || stakeAmount == '') return false
    if (!baseBalance || Number(baseBalance) == 0) return false
    if (BN(stakeAmount).gt(BN(baseBalance?.toExact()))) {
      return true
    }
    return false
  }, [baseBalance, stakeAmount])

  const addTransaction = useTransactionAdder()
  const handleStake = useCallback(async () => {
    if (!stakeAmount || !StakingContract) return

    setAttemptingTxn(true)
    try {
      const response = await StakingContract.deposit(numberToHex(stakeAmount))
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.STAKE,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('stake error', error)
    }
  }, [StakingContract, addTransaction, stakeAmount])

  const onUserMaxTab = useCallback(
    (value: number | string) => {
      if (!baseBalance || Number(baseBalance.toExact()) == 0) return
      const max = BN(baseBalance.toExact()).multipliedBy(value).toFixed(18)
      onUserInput(max)
    },
    [baseBalance]
  )

  return (
    <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
          <Trans>Stake {tokenName}</Trans>
        </ThemedText.TextSecondary>
        <CloseIcon onClick={handleClose} />
      </Box>
      <Box display="flex" flexDirection="column" mt="8px" gap="16px">
        <StyledInputBox>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
            <NumericalInput value={stakeAmount} onUserInput={onUserInput} align="left" />
            <Box display="flex" flexDirection="column" alignItems="flex-end" gap="8px">
              <Box display="flex" alignItems="center" gap="8px">
                <img src={tokenIcon} width="16px" height="16px" />
                <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                  {tokenName}
                </ThemedText.TextPrimary>
              </Box>
              <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                Balance:{formatAmount(baseBalance?.toFixed(), 2, true)}
              </ThemedText.TextSecondary>
            </Box>
          </Box>
          <Box mt="10px">
            <MaxTab onChange={onUserMaxTab} />
          </Box>
        </StyledInputBox>
        <StyledInfo>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px" className="info-item">
            <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
              <Trans>Staked</Trans>
            </ThemedText.TextSecondary>
            {stakeAmount && stakedAmount ? (
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
                  {formatAmount(stakedAmount, 2, true)}
                </ThemedText.TextSecondary>
                <ToIcon />
                <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                  {formatAmount(BN(stakeAmount).plus(stakedAmount), 2, true)}
                </ThemedText.TextPrimary>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
                  {formatAmount(stakedAmount, 2, true)}
                </ThemedText.TextSecondary>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px" className="info-item">
            <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
              <Trans>Est.APR</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
              {apr?.gt(0) ? formatAmount(apr, 2, true) : 0}%
            </ThemedText.TextPrimary>
          </Box>
        </StyledInfo>
        {!isApproved && <DynamicApprove />}
        <StyledStakeBtn onClick={handleStake} disabled={!isApproved || isInsufficientBalance || attemptingTxn}>
          <ThemedText.TextPrimary color="white" fontSize={14} fontWeight={700}>
            <Trans>{isInsufficientBalance ? 'Insufficient balance ' : attemptingTxn ? <Pending /> : 'STAKE'}</Trans>
          </ThemedText.TextPrimary>
        </StyledStakeBtn>
      </Box>
    </StyledDialog>
  )
}
