import PendingBox from 'assets/imgs/confirmation/pending.svg'
import DarkSearchIcon from 'assets/imgs/search_dark.svg'
import LightSearchIcon from 'assets/imgs/search_light.svg'
import LightCloseIcon from 'assets/svg/x.svg'
import DarkCloseIcon from 'assets/svg/x_dark.svg'

import { colors } from './colors'
import { opacify } from './utils'

type Theme = typeof darkTheme

const commonTheme = {
  white: colors.white,
  black: colors.black,

  tradeGreen: colors.tradeGreen,
  tradeRed: colors.tradeRed,

  chain_1: colors.networkEthereum,
  chain_3: colors.yellow400,
  chain_4: colors.primaryBtnBg,
  chain_5: colors.green400,
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_56: colors.networkBsc,
  chain_420: colors.networkOptimism,
  chain_42161: colors.networkArbitrum,
  chain_421613: colors.networkArbitrum,
  chain_80001: colors.networkPolygon,
  chain_43114: colors.networkOptimism,
  chain_137_background: colors.purple900,
  chain_10_background: colors.red900,
  chain_43114_background: colors.red900,
  chain_42161_background: colors.blue900,
  chain_56_background: colors.networkBsc,
  promotional: colors.magenta300,

  brandedGradient: 'linear-gradient(139.57deg, #FF79C9 4.35%, #FFB8E2 96.44%);',
  promotionalGradient: 'radial-gradient(101.8% 4091.31% at 0% 0%, #4673FA 0%, #9646FA 100%);',

  hoverState: opacify(24, colors.blue200),
  hoverDefault: colors.black05,

  buttonPrimaryBackground: colors.primaryBtnBg,

  long: colors.long,
  short: colors.short,
  up: colors.long,
  down: colors.short,
  successBg: colors.successBg,
  failBg: colors.failBg,
  pendingBg: colors.pendingBg,
  pendingBox: PendingBox,
  retryBg: colors.retry,

  threshold: colors.threshold,
  removeThreshold: colors.removeThreshold,
  twitterBg: colors.rewardedColor,
  textTradeBtn: colors.color28,

  cumulativeBarBg: colors.cumulative,
  luckyBorder: colors.luckyBorder,
  referralBorder: colors.referralBorder,
  referralCodeBorder: colors.referralCodeBorder,

  addAgreeBg: colors.addAgreeBg,
  blast: colors.blast,
}

export const darkTheme = {
  ...commonTheme,

  userThemeColor: colors.magentaVibrant,

  background: colors.gray800,
  backgroundBackdrop: colors.dropBg_dark,
  backgroundSurface: colors.black25,
  backgroundModule: colors.black,
  backgroundModule2: colors.color28,
  backgroundModuleShadow: colors.black25,
  backgroundInter: colors.white,
  backgroundOutside: colors.white,
  backgroundInteractive: colors.white05,
  backgroundFloating: opacify(12, colors.white),
  backgroundOutline: colors.white08,
  backgroundScrim: opacify(32, colors.gray900),
  backgroundScrolledSurface: opacify(72, colors.gray900),
  chartblack54: colors.chartblack54,
  chartblack87: colors.chartblack87,

  startPriceBg: colors.transparent,
  startPriceBox: colors.black25,

  textTitle: colors.white87,
  textPrimary: colors.white87,
  textSecondary: colors.white54,
  textTertiary: colors.white38,
  textButtonHover: colors.pink500,
  textTab: colors.color84,
  textTabActive: colors.black,
  textMore: colors.tabBgActive,
  textAddButton: colors.colorF0,
  textReward: colors.rewardedColor,
  themeBg: colors.black1,
  themeText: colors.white,
  voteInputBg: colors.inputBg_dark,
  voteInputBorder: colors.white05,

  closeIconBg: colors.white5,
  airdropBg: colors.black5,

  tabActiveBg: colors.white2,
  hoverBg: colors.black2,

  accentAction: colors.primaryBtnBg,
  accentActive: colors.primaryBtnBg,
  accentSuccess: colors.success,
  accentWarning: colors.gold200,
  accentFailure: colors.fail,
  accentCritical: colors.red300,

  accentActionSoft: opacify(24, colors.primaryBtnBg),
  accentActiveSoft: opacify(24, colors.primaryBtnBg),
  accentSuccessSoft: opacify(24, colors.success),
  accentWarningSoft: opacify(24, colors.gold200),
  accentFailureSoft: opacify(12, colors.fail),

  accentTextDarkPrimary: opacify(80, colors.gray900),
  accentTextDarkSecondary: opacify(60, colors.gray900),
  accentTextDarkTertiary: opacify(24, colors.gray900),

  accentTextLightPrimary: colors.gray50,
  accentTextLightSecondary: opacify(72, colors.gray50),
  accentTextLightTertiary: opacify(12, colors.gray50),

  deepShadow: '12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32);',
  shallowShadow: '4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12);',

  networkDefaultShadow: `0px 40px 120px ${opacify(16, colors.primaryBtnBg)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: `rgba(255,255,255,0.07)`,
  searchOutline: `rgba(255,255,255,0.07)`,

  swapSectionBorder: colors.white08,
  tradeSectionBorder: colors.transparent,
  tradeSectionBackground: colors.black,
  sectionBorder: colors.white16,

  logoPath: colors.white,
  backgroundHover: colors.hoverBg,
  buttonDisabledBackground: colors.disabledColor_dark,
  buttonDisabledText: colors.disabledText,
  connectBtnBackground: colors.connectBg_dark,
  networkBg: colors.white,
  rewardBtnBackground: colors.claimBtn,
  backgroundTab: colors.colorE9,
  backgroundTabActive: colors.tabBgActive,
  backgroundPreview: colors.transparent,
  backgroundPreviewBorder: colors.white08,
  backgroundTo: colors.previewBg,
  badgeBackground: colors.badgeGradient,
  maxBtnBackground: colors.maxBtnBg,
  removeBackground: colors.removeBg_dark,
  activeBorder: colors.activeBg,

  increaseBtnBg: colors.increaseBg,

  backgroundSelector: colors.color16,
  backgroundSelectorActive: colors.primaryBtn_dark,
  textSelector: colors.white5,
  textActiveSelector: colors.color28,

  navActiveText: colors.black87,
  navText: colors.white54,
  navActiveBg: colors.white,
  navActiveBg_m: 'linear-gradient(180deg, #1EA7E4 0%, #01BBBE 100%)',

  menuHover: colors.menuActive,

  moreBorder: colors.moreColor,
  primaryBorder: colors.white08,
  secondaryBorder: colors.white16,
  iconBorder: colors.white16,

  sliderThumb: colors.colorD9,
  sliderTrack: colors.color32,

  closeIcon: DarkCloseIcon,
  iconBg: colors.black54,
  iconHoverBg: colors.black87,

  agreeBg: colors.agreeBg_dark,

  primaryBtn: colors.primaryBtn_dark,
  primaryBtn_hover: colors.primaryBtn_hover_dark,
  primaryBtn_disabled: colors.primaryBtn_disabled_dark,
  secondaryBtn_hover_bg: colors.white,
  secondaryBtn_hover_text: colors.black,
  secondaryBtn_border: colors.secondaryBtn_border_dark,
  claimBtn: colors.claimBtn,
  claimBtn_border: colors.primaryBtn_dark,
  claimBtn_text: colors.black,

  swapSectionBg: colors.black2,
  optionsCardBg: colors.color28,

  paginationActiveBg: colors.white1,
  progressPrimary: colors.white1,
  cumulativeInfoBg: colors.black2,
  referralBg: colors.referralBg,
  referralSpins: colors.referralBorder,
  referralCode: colors.referralCode_dark,
  referralCodeBg: colors.transparent,
  referralLinkBg: colors.referralLinkBg,
  referralCopy: colors.referralCode_dark,
  referralCopyLink: colors.referralCode_dark,
  postBg: colors.postBg,
  postBgHover: colors.postBgHover,
  postText: colors.white,
  referralTitleBg: colors.referralBorder,
  referralTitleBorder: colors.white16,
  toBg: colors.white1,

  attention_bg: colors.attention_bg_dark,
  primaryTitle: colors.white,
  searchIcon: DarkSearchIcon,


  stakeBtnBg: colors.white1,
}

export const lightTheme: Theme = {
  ...commonTheme,

  //  rogeue
  logoPath: colors.connectBg_light,
  backgroundHover: colors.hoverBg,

  userThemeColor: colors.magentaVibrant,

  background: '#faf9fa',
  backgroundBackdrop: colors.dropBg_light,
  backgroundSurface: colors.white,
  backgroundModule: colors.white,
  backgroundModule2: colors.white,
  backgroundModuleShadow: colors.white25,
  backgroundInter: colors.black,
  backgroundOutside: colors.white,
  backgroundInteractive: colors.black05,
  backgroundFloating: opacify(8, colors.gray700),
  backgroundOutline: colors.black08,
  backgroundScrim: opacify(60, colors.gray900),
  backgroundScrolledSurface: opacify(72, colors.white),

  startPriceBg: colors.white,
  startPriceBox: colors.transparent,

  textTitle: colors.connectBg_light,
  textPrimary: colors.black87,
  textSecondary: colors.black54,
  textTertiary: colors.black38,
  textButtonHover: colors.pink500,
  textTab: colors.color84,
  textTabActive: colors.white,
  textMore: colors.tabBgActive,
  textAddButton: colors.colorF0,
  textReward: colors.rewardedColor,
  themeBg: colors.white,
  themeText: colors.black,
  voteInputBg: colors.colorF0,
  voteInputBorder: colors.black08,

  closeIconBg: colors.black5,
  airdropBg: colors.black5,

  tabActiveBg: colors.colorF0,
  hoverBg: colors.white2,

  accentAction: colors.primaryBtnBg,
  accentActive: colors.primaryBtnBg,
  accentSuccess: colors.success,
  accentWarning: colors.gold400,
  accentFailure: colors.fail,
  accentCritical: colors.red400,

  accentActionSoft: opacify(12, colors.primaryBtnBg),
  accentActiveSoft: opacify(24, colors.primaryBtnBg),
  accentSuccessSoft: opacify(24, colors.success),
  accentWarningSoft: opacify(24, colors.gold400),
  accentFailureSoft: opacify(12, colors.fail),

  accentTextDarkPrimary: opacify(80, colors.gray900),
  accentTextDarkSecondary: opacify(60, colors.gray900),
  accentTextDarkTertiary: opacify(24, colors.gray900),

  accentTextLightPrimary: colors.white,
  accentTextLightSecondary: opacify(72, colors.gray50),
  accentTextLightTertiary: opacify(12, colors.gray50),

  deepShadow:
    '8px 12px 20px rgba(51, 53, 72, 0.04), 4px 6px 12px rgba(51, 53, 72, 0.02), 4px 4px 8px rgba(51, 53, 72, 0.04);',
  shallowShadow:
    '6px 6px 10px rgba(51, 53, 72, 0.01), 2px 2px 6px rgba(51, 53, 72, 0.02), 1px 2px 2px rgba(51, 53, 72, 0.02);',

  networkDefaultShadow: `0px 40px 120px ${opacify(12, colors.primaryBtnBg)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: opacify(4, colors.white),
  searchOutline: opacify(1, colors.black),

  swapSectionBorder: colors.black08,
  tradeSectionBorder: colors.black08,
  tradeSectionBackground: colors.transparent,
  sectionBorder: colors.black16,

  buttonDisabledBackground: colors.disabledColor_light,
  buttonDisabledText: colors.disabledText,
  connectBtnBackground: colors.connectBg_light,
  networkBg: colors.connectBg_light,
  rewardBtnBackground: colors.claimBtn,
  backgroundTab: colors.colorE9,
  backgroundTabActive: colors.tabBgActive,
  backgroundPreview: colors.previewBg,
  backgroundPreviewBorder: colors.white08,
  backgroundTo: colors.previewBg,
  badgeBackground: colors.badgeGradient,
  maxBtnBackground: colors.maxBtnBg,
  removeBackground: colors.removeBg_light,
  activeBorder: colors.activeBg,

  increaseBtnBg: colors.increaseBg,

  backgroundSelector: colors.black05,
  backgroundSelectorActive: colors.tabBgActive,
  textSelector: colors.black54,
  textActiveSelector: colors.colorF0,

  navActiveText: colors.black87,
  navText: colors.black54,
  navActiveBg: colors.white,
  navActiveBg_m: 'linear-gradient(180deg, #1EA7E4 0%, #01BBBE 100%)',

  menuHover: colors.menuActive,

  moreBorder: colors.moreColor,
  primaryBorder: colors.black08,
  secondaryBorder: colors.black16,
  iconBorder: colors.white16,

  sliderThumb: colors.colorD9,
  sliderTrack: colors.color32,
  chartblack54: colors.chartblack54,
  chartblack87: colors.chartblack87,

  closeIcon: LightCloseIcon,
  iconBg: colors.white54,
  iconHoverBg: colors.white87,

  agreeBg: colors.agreeBg_light,

  primaryBtn: colors.primaryBtn_light,
  primaryBtn_hover: colors.primaryBtn_hover_light,
  primaryBtn_disabled: colors.primaryBtn_disabled_light,
  secondaryBtn_hover_bg: colors.connectBg_light,
  secondaryBtn_hover_text: colors.colorF0,
  secondaryBtn_border: colors.secondaryBtn_border_light,
  claimBtn: colors.claimBtn,
  claimBtn_border: colors.claimBtn_border_light,
  claimBtn_text: colors.colorF0,

  swapSectionBg: colors.white,
  optionsCardBg: colors.white,

  paginationActiveBg: colors.black1,
  progressPrimary: colors.black1,
  cumulativeInfoBg: colors.white2,
  referralBg: colors.black05,
  referralSpins: colors.black05,
  referralCode: colors.removeBg_light,
  referralCodeBg: colors.referralCodeBg,
  referralLinkBg: colors.removeBg_light,
  referralCopy: colors.removeBg_light,
  referralCopyLink: colors.white,
  referralTitleBg: colors.referralTitleBg_light,
  referralTitleBorder: colors.black08,
  postBg: colors.postBg,
  postBgHover: colors.postBgHover,
  postText: colors.white,
  toBg: colors.black05,

  attention_bg: colors.attention_bg_light,
  primaryTitle: colors.referralBorder,
  searchIcon: LightSearchIcon,

  stakeBtnBg: colors.black05,
}
