import './toast.css'

import { Box } from '@mui/material'
import { PortfolioRowWrapper } from 'components/AccountDrawer/MiniPortfolio/PortfolioRow'
import AnimatedConfirmation from 'components/AnimatedConfirmation'
import { AutoColumn } from 'components/Column'
import {
  BscChainIcon,
  PopupAlertTriangle,
  PopupErrorRight,
  PopupInfoRight,
  StyledClose,
} from 'components/Popups/PopupContent'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const toastOptions = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 10000,
  icon: false,
  closeButton: false,
  // limit: 1,
  hideProgressBar: true,
}

const StyledToastContent = styled.div<{ height?: string }>`
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.deepShadow};
  transition: ${({ theme }) => `visibility ${theme.transition.duration.fast} ease-in-out`};

  display: flex;
  align-items: center;
`

export const ToastSuccess = (msg: any) => {
  const handlerClose = () => {
    toast.dismiss()
  }

  return toast.success(
    <StyledToastContent>
      <StyledClose onClick={handlerClose} />
      <BscChainIcon />
      <Box mr="8px" height="100%">
        <PopupInfoRight />
      </Box>
      <PortfolioRowWrapper>
        <AnimatedConfirmation size="48px" />
        <AutoColumn gap="6px" style={{ zIndex: 9999 }}>
          <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
            {msg}
          </ThemedText.TextPrimary>
        </AutoColumn>
      </PortfolioRowWrapper>
    </StyledToastContent>,
    toastOptions
  )
}

export const ToastError = (msg: any) => {
  const handlerClose = () => {
    toast.dismiss()
  }

  return toast.error(
    <StyledToastContent>
      <StyledClose onClick={handlerClose} />
      <BscChainIcon />
      <Box mr="8px" height="100%">
        <PopupErrorRight />
      </Box>
      <PortfolioRowWrapper>
        <PopupAlertTriangle size="48px" />
        <AutoColumn gap="6px" style={{ zIndex: 9999 }}>
          <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
            {msg}
          </ThemedText.TextPrimary>
        </AutoColumn>
      </PortfolioRowWrapper>
    </StyledToastContent>,
    toastOptions
  )
}

export const ToastInfo = (msg: any) => {
  const handlerClose = () => {
    toast.dismiss()
  }

  return toast.info(
    <StyledToastContent>
      <StyledClose onClick={handlerClose} />
      <BscChainIcon />
      <Box mr="8px" height="100%">
        <PopupInfoRight />
      </Box>
      <PortfolioRowWrapper>
        <AnimatedConfirmation size="48px" />
        <AutoColumn gap="6px" style={{ zIndex: 9999 }}>
          <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
            {msg}
          </ThemedText.TextPrimary>
        </AutoColumn>
      </PortfolioRowWrapper>
    </StyledToastContent>,
    toastOptions
  )
}
