import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import Column from 'components/Column'
import Row from 'components/Row'
import { ToastSuccess } from 'components/Toast'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { computeNumUnit } from 'utils/formatNum'

import { usePoolLiquidity } from '../hooks'
import { handleCreatedTime } from '../InfoList'
import { LinkBox2 } from '../LinkBox'
import { StyledCopyIcon } from '../MarketList'
import { useActiveChainId } from 'connection/useActiveChainId'
import { isBsc } from 'constants/tokens'

const StyledInfo = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledDesc = styled.div`
  word-break: break-word;
  width: auto;
  max-width: 300px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xs}px`}) {
    width: 100%;
  }
`

export default function Info({ infoData }: any) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)

  const { wethBalance, tokenBalance } = usePoolLiquidity(infoData.launchPoolInfo.pool.id, infoData.address)
  const {chainId} = useActiveChainId()
  const [, setCopied] = useCopyClipboard()
  const handlerCopy = useCallback(
    (event: any) => {
      event.preventDefault()
      setCopied(infoData.address ?? '')
      ToastSuccess('Copied to clipboard')
    },
    [infoData.address, setCopied]
  )

  return (
    <StyledInfo>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={isVerySmallScreen ? '16px' : isSmallScreen ? '16px' : '60px'}
        className="flex-1"
      >
        <Box display="flex" gap="16px">
          <img src={infoData?.metadata?.image_url} width="60px" height="60px" />
          <Column align="flex-start" gap="8px">
            <Box display="flex" alignItems="center" gap="4px">
              <ThemedText.TextPrimary fontSize={18} fontWeight={800}>
                <Trans>{infoData.symbol}</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>{infoData.name}</Trans>
              </ThemedText.TextSecondary>
            </Box>
            <Box display="flex" gap="8px">
              <ThemedText.TextPrimary fontSize={12} fontWeight={400}>
                <Trans>{shortenAddress(infoData.address)}</Trans>
              </ThemedText.TextPrimary>
              <StyledCopyIcon onClick={handlerCopy} />
            </Box>
            {!isSmallScreen && (
              <StyledDesc>
                <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                  <Trans>{infoData?.metadata?.description}</Trans>
                </ThemedText.TextSecondary>
              </StyledDesc>
            )}
          </Column>
        </Box>
        <Box
          display="flex"
          alignItems={isVerySmallScreen ? 'flex-start' : 'center'}
          flexDirection={isVerySmallScreen ? 'column' : 'row'}
          gap={isVerySmallScreen ? '16px' : isSmallScreen ? '40px' : '60px'}
        >
          <Box
            display="flex"
            flexDirection={isVerySmallScreen ? 'row' : 'column'}
            justifyContent={isVerySmallScreen ? 'space-between' : 'center'}
            alignItems={isVerySmallScreen ? 'center' : 'flex-start'}
            width={isVerySmallScreen ? '100%' : 'auto'}
            gap="2px"
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>MKT Cap</Trans>
            </ThemedText.TextSecondary>
            <Box display="flex" alignItems="center" gap="4px">
              <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
                ${computeNumUnit(infoData.marketCap)}
              </ThemedText.TextPrimary>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={isVerySmallScreen ? 'row' : 'column'}
            justifyContent={isVerySmallScreen ? 'space-between' : 'center'}
            alignItems={isVerySmallScreen ? 'center' : 'flex-start'}
            width={isVerySmallScreen ? '100%' : 'auto'}
            gap="2px"
          >
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>Liquidity</Trans>
            </ThemedText.TextSecondary>
            <Box display="flex" alignItems="center" gap="4px">
              <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
                {computeNumUnit(wethBalance?.toExact())} {isBsc(chainId) ? 'BNB':'ETH'}
              </ThemedText.TextPrimary>
              <ThemedText.TextTertiary fontSize={12} fontWeight={700}>
                |
              </ThemedText.TextTertiary>
              <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
                {computeNumUnit(tokenBalance?.toExact())} {infoData.symbol}
              </ThemedText.TextPrimary>
            </Box>
          </Box>
        </Box>
      </Box>
      <Column gap="16px" width={isSmallScreen ? '100%' : '300px'} align={isSmallScreen ? 'flex-start' : 'flex-end'}>
        <Column gap="4px" align={isSmallScreen ? 'flex-start' : 'flex-end'}>
          <Row gap="8px">
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>Created</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
              <Trans>{shortenAddress(infoData.createdOrigin)}</Trans>
            </ThemedText.TextPrimary>
          </Row>
          <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
            {handleCreatedTime(infoData.createdAtTimestamp)}
          </ThemedText.TextSecondary>
        </Column>
        <LinkBox2 metadata={infoData.metadata} justify={isSmallScreen ? 'flex-start' : 'flex-end'} />
        {isSmallScreen && (
          <StyledDesc>
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>{infoData?.metadata?.description}</Trans>
            </ThemedText.TextSecondary>
          </StyledDesc>
        )}
      </Column>
    </StyledInfo>
  )
}
